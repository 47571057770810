import React, { useContext } from 'react';
import { ProductsContext } from '../../contexts/ProductsContext';

const Search = () => {
	const { handleSubmitSearch, handleChangeSearch } =
		useContext(ProductsContext);

	return (
		<div className="search-box">
			<form className="search-form" onSubmit={handleSubmitSearch}>
				<div className="relative">
					<input
						type="search"
						id="default-search"
						className="search-field"
						style={{minWidth: '200px'}}
						placeholder="O que deseja procurar?"
						required
						onChange={({ target: { value } }) => handleChangeSearch(value)}
					/>
					<button type="submit" className="btn-pesquisar" style={{maxWidth: '50px'}}>
						<div style={{display: 'flex', justifyContent: 'center'}}>
							<span className="material-symbols-outlined">search</span>
						</div>
					</button>
				</div>
			</form>
		</div>
	);
};

export default Search;
