import { createContext, useState } from 'react';

export const ModalContext = createContext(null);

export const ModalStateProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [opacity, setOpacity] = useState(0);

	// ACTIONS
	function toggleModal() {
		setOpacity(0);
		setIsOpen(!isOpen);
	}

	function afterOpen() {
		setTimeout(() => {
			setOpacity(1);
		}, 100);
	}

	function beforeClose() {
		return new Promise((resolve) => {
			setOpacity(0);
			setTimeout(resolve, 300);
		});
	}

	const value = {
		isOpen,
		setIsOpen,
		opacity,
		setOpacity,
		toggleModal,
		afterOpen,
		beforeClose,
	};
	return (
		<ModalContext.Provider value={value}>{children}</ModalContext.Provider>
	);
};
