import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const STORE_ID = process.env.REACT_APP_STORE_ID;

export const CompraRapidaContext = createContext(null);

export const CompraRapidaProvider = ({ children }) => {
	// SELECTED SERIE STATE
	const [selectedSerie, setSelectedSerie] = useState('');
	const [productsBySerieId, setProductsBySerieId] = useState();
	const [totalCartSum, setTotalCartSum] = useState(0);
	const [cartCount, setCartCount] = useState(1);

	// FETCH PRODUCTS BY SERIE_ID

	let handleComboboxSelection = (selectedSerie) => {
		setSelectedSerie(selectedSerie);
		const serie_id = selectedSerie.serie_id;
		const options = {
			method: 'POST',
			url: `${BASE_URL}/products/list-product-by-serie-totvs-id/${STORE_ID}/visible/enabled`,
			headers: { 'Content-Type': 'application/json' },
			data: {
				seriesTOTVSID: [`${serie_id}`],
			},
		};

		axios
			.request(options)
			.then(function (response) {
				setProductsBySerieId(
					response.data.products?.map((item) => item.itemTOTVS)
				);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	// set cart total
	useEffect(() => {
		const newCartTotal = productsBySerieId?.reduce(
			(total, cartItem) => total + 1 * cartItem.PRECO,
			0
		);
		setTotalCartSum(
			(Math.round(newCartTotal * 100 + Number.EPSILON) / 100).toFixed(2)
		);
	}, [productsBySerieId]);

	const value = {
		selectedSerie,
		setSelectedSerie,
		handleComboboxSelection,
		productsBySerieId,
		totalCartSum,
		setTotalCartSum,
		cartCount,
		setCartCount,
	};
	return (
		<CompraRapidaContext.Provider value={value}>
			{children}
		</CompraRapidaContext.Provider>
	);
};
