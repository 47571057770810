import React from 'react';

import TopNav from './TopNav';
import BottomNav from './BottomNav';
import './NavbarMobile.styles.scss';

const NavbarMobile = () => {
	return (
		<>
			<TopNav />
			<BottomNav />
		</>
	);
};

export default NavbarMobile;
