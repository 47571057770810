import React, { useContext } from 'react';
import './CartIcon.styles.scss';

import { CartContext } from '../../../contexts/CartContext';

const CartIcon = () => {
	const { cartCount, toggleCart } =
		useContext(CartContext);

	const toggleIsCartOpen = () => {
		toggleCart();
	};

	return (
		<>
			<button className="cart-btn" onClick={toggleIsCartOpen}>
				<span className="cart-icon material-symbols-outlined">
					shopping_bag
				</span>
				<span className="cart-item-count">{cartCount}</span>
			</button>
		</>
	);
};

export default CartIcon;
