import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import styled from 'styled-components';

import './index.css';
import App from './App';
import ScrollToTop from './hooks/ScrollToTop';

// CONTEXT PROVIDERS
import { ProductsProvider } from './contexts/ProductsContext';
import { CartProvider } from './contexts/CartContext';
import { CategoryProvider } from './contexts/CategoryContext';
import { ModalStateProvider } from './contexts/ModalContext';
import { FormProvider } from './contexts/FormContext';
import { PaymentProvider } from './contexts/PaymentContext';
import { AccountProvider } from './contexts/AccountContext';
import { StepperProvider } from './contexts/StepperContext';
import { CompraRapidaProvider } from './contexts/CompraRapidaContext';
import { SeriesProvider } from './contexts/SeriesContext';
import { GlobalProvider } from './contexts/GlobalContext';
import { VinculoStateProvider } from './contexts/VinculoContext';

const FadingBackground = styled(BaseModalBackground)`
	opacity: ${(props) => props.opacity};
	transition: all 0.3s ease-in-out;
	z-index: 20001;
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<ModalProvider backgroundComponent={FadingBackground}>
			<GlobalProvider>
				<AccountProvider>
					<CompraRapidaProvider>
						<CategoryProvider>
							<ProductsProvider>
								<SeriesProvider>
									<ModalStateProvider>
										<VinculoStateProvider>
											<CartProvider>
												<FormProvider>
													<StepperProvider>
														<PaymentProvider>
															<ScrollToTop />
															<App />
														</PaymentProvider>
													</StepperProvider>
												</FormProvider>
											</CartProvider>
										</VinculoStateProvider>
									</ModalStateProvider>
								</SeriesProvider>
							</ProductsProvider>
						</CategoryProvider>
					</CompraRapidaProvider>
				</AccountProvider>
			</GlobalProvider>
		</ModalProvider>
	</BrowserRouter>
);
