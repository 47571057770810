import { createContext, useState } from 'react';

export const StepperContext = createContext(null);

export const StepperProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);

	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const prevStep = () => {
		setCurrentStep(currentStep - 1);
	};
	const value = { currentStep, setCurrentStep, nextStep, prevStep };
	return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>;
};
