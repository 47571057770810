import React from 'react';
import Head from './Head';
import Header from './Header';
import NavMenu from './NavMenu';
import './Navbar.styles.scss';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
	const location = useLocation();
	return (
		<>
			<Head />
			<Header />
			{location.pathname === '/' && <NavMenu />}
		</>
	);
};

export default Navbar;
