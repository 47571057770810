import { createContext, useState } from 'react';

export const VinculoContext = createContext(null);

export const VinculoStateProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [opacity, setOpacity] = useState(0);
	const [selectedStudent, setSelectedStudent] = useState('');

	// ACTIONS
	function toggleVinculoModal(Student) {
		setSelectedStudent(Student);
		setOpacity(0);
		setIsOpen(!isOpen);
	}

	function afterOpen() {
		setTimeout(() => {
			setOpacity(1);
		}, 100);
	}

	function beforeClose() {
		return new Promise((resolve) => {
			setOpacity(0);
			setTimeout(resolve, 300);
		});
	}

	const value = {
		isOpen,
		setIsOpen,
		opacity,
		setOpacity,
		toggleVinculoModal,
		afterOpen,
		beforeClose,
		selectedStudent,
		setSelectedStudent,
	};
	return (
		<VinculoContext.Provider value={value}>{children}</VinculoContext.Provider>
	);
};
