import React, { useContext } from 'react';
import './NavbarMobile.styles.scss';
import { useNavigate } from 'react-router-dom';
import { ProductsContext } from '../../contexts/ProductsContext';

const BottomNav = () => {
	const { navMenuHandler } = useContext(ProductsContext);
	const navigate = useNavigate();
	return (
		<>
			<div className="bottomnav-wrapper">
				<div className="bottomnav-container">
					<div className="home">
						<button className="bottomnav-btn" onClick={() => navigate('/')}>
							<span className="material-symbols-outlined">home</span>
						</button>
					</div>
					<div className="categoria">
						<button
							className="bottomnav-btn"
							onClick={() => navigate('/categoria/material-didatico')}>
							<span className="material-symbols-outlined">widgets</span>
						</button>
					</div>
					<div className="pesquisa">
						<button className="bottomnav-btn" onClick={navMenuHandler}>
							<span className="material-symbols-outlined">search</span>
						</button>
					</div>
					<div className="compra-rapida">
						<button
							className="bottomnav-btn"
							onClick={() => navigate('/compra-rapida')}>
							<span className="material-symbols-outlined">
								shopping_cart_checkout
							</span>
						</button>
					</div>
					<div className="atendimento">
						<button
							className="bottomnav-btn"
							onClick={() => navigate('/atendimento')}>
							<span className="material-symbols-outlined">chat</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default BottomNav;
