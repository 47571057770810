import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const FormContext = createContext(null);

export const FormProvider = ({ children }) => {
	// UF, CITY STATE
	const [ufs, setUfs] = useState([]);
	const [cities, setCities] = useState([]);
	const [selectedUf, setSelectedUf] = useState('');

	// FETCH UF
	useEffect(() => {
		axios
			.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
			.then((response) => {
				setUfs(response.data);
			});
	}, []);

	// FETCH CIDADE
	useEffect(() => {
		axios
			.get(
				`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
			)
			.then((response) => {
				setCities(response.data);
			});
	}, [selectedUf]);

	// HANDLER FUNCTION
	function handleSelectedUf(e) {
		setSelectedUf(e.target.value);
	}

	const value = {
		ufs,
		setUfs,
		cities,
		setCities,
		selectedUf,
		setSelectedUf,
		handleSelectedUf,
	};
	return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
