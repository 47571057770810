const calculateInstallments = (paymentPlan, TIPO, parcelas) => {
    if (TIPO === 'P' || TIPO === 'M') {
        return parcelas
    } else if (TIPO === 'S' || paymentPlan.turnYear || new Date().getFullYear() < Number(paymentPlan.schoolYear.slice(0, -2))) {
        return paymentPlan.maxInstallments;
    } else {
        let currentInstallments = paymentPlan.maxInstallments > (12 - new Date().getMonth()) ?
            (12 - new Date().getMonth()) : paymentPlan.maxInstallments;

        return currentInstallments;
    }
}

export { calculateInstallments };