import React from 'react';
import './Footer.styles.scss';
import FooterBottom from './FooterBottom/FooterBottom';
import FooterTop from './FooterTop/FooterTop';
import Newsletter from './Newsletter/Newsletter';

const Footer = () => {
	return (
		<>
			<div className="footer-wrapper">
				<Newsletter />
				<FooterTop />
				<FooterBottom />
			</div>
		</>
	);
};

export default Footer;
