import React, { useContext } from 'react';
import { ProductsContext } from '../../contexts/ProductsContext';
import './NavbarMobile.styles.scss';

function Search() {
	const { handleSubmitSearch, handleChangeSearch } =
		useContext(ProductsContext);

	return (
		<div className="search-mobile-wrapper">
			<form className="search-form" onSubmit={handleSubmitSearch}>
				<div className="relative">
					<div className="search-icon">
						<span className="material-symbols-outlined">search</span>
					</div>
					<input
						type="search"
						id="default-search"
						className="search-field"
						placeholder="Busque por produto"
						required
						onChange={({ target: { value } }) => handleChangeSearch(value)}
					/>
					<button type="submit" className="btn-pesquisar">
						Pesquisar
					</button>
				</div>
			</form>
		</div>
	);
}

export default Search;
