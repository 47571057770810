import React from 'react';
import './FooterBottom.styles.scss';
import IconBoleto from '../../../assets/Icons/icon-boleto.svg';
import IconCardVisa from '../../../assets/Icons/icon-card-visa.svg';
import IconCardMaster from '../../../assets/Icons/icon-card-master.svg';
import IconCardElo from '../../../assets/Icons/icon-card-elo.svg';

const FooterBottom = () => {
	return (
		<>
			<div className="footer-bottom-wrapper">
				<div className="footer-bottom-container">
					<div className="footer-bottom-left">
						<div className="cnpj-top">
							<h3>© {new Date().getFullYear()} Pensar Store | Todos os direitos reservados</h3>
						</div>
						<div className="cnpj-bottom">
							<p>
								M E F COMERCIO DE LIVROS E ALIMENTOS LTDA. - CNPJ:
								32.063.701/0001-66
							</p>
						</div>
					</div>
					<div className="footer-bottom-right">
						<img src={IconBoleto} alt="" />
						<img src={IconCardVisa} alt="" />
						<img src={IconCardMaster} alt="" />
						<img src={IconCardElo} alt="" />
					</div>
				</div>
			</div>
		</>
	);
};

export default FooterBottom;
